import { css } from "aphrodite";
import styles from "./ExcellentReviews.styles";
import React from "react";
import TrustPilotSmall from "./TrustPilotSmall";

const ExcellentReviews = () => (
  <div className={css(styles.root)}>
    <div style={{ flex: "0 0 30%" }}>
      {/*  {[0, 1, 2, 3].map((star) => (
        <img src={require('../../../assets/trust/single-star-transparent.svg')} className={css(styles.trustPilotStar)} key={star} />
      ))}
      <img src={require('../../../assets/trust/single-star-transparent.svg')} className={css(styles.trustPilotStar, styles.trustPilotNoStar)} /> */}
      <TrustPilotSmall />
      {/* <div className={css(styles.noOfBookings)}>
        Join over 1,000 + happy customers
      </div> */}
    </div>
    <div />
  </div>
);

export default ExcellentReviews;
