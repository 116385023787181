import COLORS from '../../../styles/colors';
import QUERIES from '../../../styles/queries';
import { StyleSheet } from 'aphrodite';

const styles = StyleSheet.create({
  root: {
    textAlign: 'center',
    width: '100%',
    marginTop: 64,
    marginBottom: 64,
    [QUERIES.mobile]: {
      paddingBottom: 64
    }
  },
  phoneNumber: {
    fontSize: 32,
    textDecoration: 'none',
    color: COLORS.lightBlack,
    fontWeight: 'bold',
    [QUERIES.desktop]: {
      fontSize: 42
    }
  },
  bernardImg: {
    width: 60,
    height: 60,
    borderRadius: 60
  },
  bernardImgContainer: {
    width: 60,
    height: 60,
    position: 'relative',
    margin: '0 auto'
  },
  onlineCircle: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: 18,
    height: 18,
    borderRadius: 18,
    border: '2px solid white',
    background: COLORS.kgGreen
  },
});

export default styles;
