import React from 'react';
import AddressesContainer from '../containers/bookingStep0/RoutePlanner/RoutePlannerContainer';
import Layout from '../containers/gatsby/layout';

const AddressesPage = (props) => (
  <Layout hideHeader={true} hideFooter={true} location={props.location}>
    <AddressesContainer />
  </Layout>
);

export default AddressesPage;
