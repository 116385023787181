import React from "react";
import styles from "./RoutePlanner.styles";
import { css } from "aphrodite";
import MenuItem from "@material-ui/core/MenuItem";
import PrimaryButton from "../../../components/buttons/PrimaryButton/PrimaryButton";
import EditButton from "@material-ui/icons/EditRounded";
import RemoveButton from "@material-ui/icons/Delete";
import TimerIcon from "@material-ui/icons/Commute";
import LiftingIcon from "@material-ui/icons/TimerRounded";
import ExcellentReviews from "../../../components/booking/ExcellentReviews/ExcellentReviews";
import MapView from "../../../components/booking/MapView";
import GiveBernyACall from "../../../components/booking/GiveBernyACall/GiveBernyACall";
var moment = require("moment");

const RoutePlanner = (props) => {
  const durationInMinutes = moment.duration(
    props.bookingDistance.duration,
    "minutes"
  );
  return (
    <div className={css(styles.root)}>
      {/*<img src={require('../../../assets/images/map.png')} className={css(styles.bgImage)} />*/}

      <div className={css(styles.content)}>
        <div className={css(styles.inputBoxes)}>
          <div className={css(styles.innerContent)}>
            <div className={css(styles.inputContainer)}>
              <input
                id="pickup-search"
                placeholder="Enter pickup address"
                // type="search"
                value={props.pickup}
                onChange={props.handlePickupChange}
                className={css(styles.customInput, styles.pickupInput)}
                onFocus={props.handleFocus}
              />
              <EditButton
                className={css(styles.editButton)}
                onClick={() => props.editAddress("pickup")}
              />

              {props.showPickupSuggestions &&
                props.bookingAddresses.suggestions.pickup.length >= 1 && (
                  <div className={css(styles.suggestionsContainer)}>
                    {props.bookingAddresses.suggestions.pickup.map((hit) => (
                      <MenuItem
                        key={hit.udprn}
                        onClick={() => props.selectPickupAddress(hit)}
                      >
                        {hit.suggestion}
                      </MenuItem>
                    ))}
                  </div>
                )}
            </div>
          </div>

          {props.bookingAdditionalStops.stops.map((additionalStop, index) => (
            <div className={css(styles.innerContent)} key={index}>
              <div
                className={css(
                  styles.inputContainer,
                  styles.additionalInputContainer
                )}
              >
                <input
                  id={`additional-stop-${index}`}
                  placeholder={`Additional stop ${index + 1}`}
                  // type="search"
                  value={props[`additionalStop${index}`]}
                  onChange={(e) => props.handleAdditionalStopChange(e, index)}
                  className={css(
                    styles.customInput,
                    styles.additionalInput,
                    styles.dropoffInput
                  )}
                  onFocus={props.handleFocus}
                />

                <RemoveButton
                  onClick={() => props.removeStop(index)}
                  className={css(styles.removeBtn)}
                />
                <EditButton
                  onClick={() => props.editAdditionalStopAddress(index)}
                  className={css(styles.editButton)}
                />

                {props.bookingAddresses.suggestions[`additionalStop${index}`] &&
                  props[`showAdditionalStop${index}Suggestions`] &&
                  props.bookingAddresses.suggestions[`additionalStop${index}`]
                    .length >= 1 && (
                    <div className={css(styles.suggestionsContainer)}>
                      {props.bookingAddresses.suggestions[
                        `additionalStop${index}`
                      ].map((hit) => (
                        <MenuItem
                          key={hit.udprn}
                          onClick={() =>
                            props.selectAdditionalStopAddress(hit, index)
                          }
                        >
                          {hit.suggestion}
                        </MenuItem>
                      ))}
                    </div>
                  )}
              </div>
            </div>
          ))}

          <div className={css(styles.innerContent)}>
            <div className={css(styles.inputContainer)}>
              <input
                id="dropoff-search"
                placeholder="Dropoff address"
                // type="search"
                value={props.dropoff}
                onChange={props.handleDropoffChange}
                className={css(styles.customInput, styles.dropoffInput)}
                onFocus={props.handleFocus}
              />
              <EditButton
                className={css(styles.editButton)}
                onClick={() => props.editAddress("dropoff")}
              />

              {props.showDropoffSuggestions &&
                props.bookingAddresses.suggestions.dropoff.length >= 1 && (
                  <div className={css(styles.suggestionsContainer)}>
                    {props.bookingAddresses.suggestions.dropoff.map((hit) => (
                      <MenuItem
                        key={hit.udprn}
                        onClick={() => props.selectDropoffAddress(hit)}
                      >
                        {hit.suggestion}
                      </MenuItem>
                    ))}
                  </div>
                )}
            </div>
          </div>

          {props.bookingAdditionalStops.stops.length < 4 && (
            <div
              style={{
                width: "100%",
                textAlign: "center",
                padding: 32,
                paddingBottom: 0,
              }}
            >
              <PrimaryButton
                className={css(styles.additionalBtn)}
                color={"white"}
                title={"+ Add additional stop"}
                onClick={props.addStop}
              />
            </div>
          )}

          <div>
            {props.bookingAddresses.dropoff.fullAddress &&
              props.bookingAddresses.pickup.fullAddress && (
                <ul className={css(styles.sellingPointsList)}>
                  <li className={css(styles.sellingPointItem)}>
                    <TimerIcon className={css(styles.sellingPointCheck)} />
                    Driving time:{" "}
                    {moment
                      .utc(durationInMinutes.asMilliseconds())
                      .format("H[h]mm")}
                  </li>
                  <li className={css(styles.sellingPointItem)}>
                    <LiftingIcon className={css(styles.sellingPointCheck)} />
                    Distance: {props.bookingDistance.distance} miles
                  </li>
                </ul>
              )}
            <PrimaryButton
              className={css(styles.getQuoteBtn)}
              loading={props.loading}
              disabled={props.disabled}
              large={true}
              title={"Get quote"}
              style={{ width: "100%", height: 64, marginTop: 64 }}
              onClick={props.goNext}
            />
          </div>
        </div>

        <div className={css(styles.mapImageContainer)}>
          <MapView
            locations={[
              props.bookingAddresses.pickup,
              ...props.bookingAdditionalStops.stops,
              props.bookingAddresses.dropoff,
            ]}
          />
        </div>
      </div>

      <GiveBernyACall />
      <ExcellentReviews />
    </div>
  );
};

export default RoutePlanner;
