import React, { Component } from 'react';
import styles from './YourQuoteIsValidFor.styles';
import { css } from 'aphrodite';
import { connect } from 'react-redux'
import { saveYourQuoteIsValidForTime } from './YourQuoteIsValidForActions';

class YourQuoteIsValidFor extends Component {

  constructor(props) {
    super(props);
    this.state = {time: {}};
    this.timer = 0;
    this.startTimer = this.startTimer.bind(this);
    this.countDown = this.countDown.bind(this);
  }

  secondsToTime(secs) {
    let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    let obj = {
      'h': hours,
      'm': minutes,
      's': seconds
    };
    return obj;
  }

  componentDidMount() {
    let timeLeftVar = this.secondsToTime(this.props.seconds);
    this.setState({time: timeLeftVar}, () => this.startTimer());


    // if (window) {
    //   window.onbeforeunload = function() {
    //     return "Are you sure you want to leave?";
    //   }
    // }

  }

  startTimer() {
    if (this.timer === 0 && this.props.seconds > 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  }

  countDown() {
    // Remove one second, set state so a re-render happens.
    let seconds = this.props.seconds - 1;
    this.setState({
      time: this.secondsToTime(seconds)
    }, () => this.props.saveTime(seconds));

    // Check if we're at zero.
    if (seconds === 0) {
      clearInterval(this.timer);
    }
  }


  render() {
    return (
      <div className={css(styles.root)}>
        Your quote is valid for {this.state.time.m} minutes and {this.state.time.s} seconds
      </div>
    )
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

}


const mapStateToProps = (state) => ({
  seconds: state.yourQuoteIsValidFor
});

const mapDispatchToProps = {
  saveTime: saveYourQuoteIsValidForTime
};

export default connect(mapStateToProps, mapDispatchToProps)(YourQuoteIsValidFor);
