import React from 'react';
import styles from './BookingHeader.styles';
import { css } from 'aphrodite';
import ArrowBack from '@material-ui/icons/ArrowBackRounded';
import PhoneIcon from '@material-ui/icons/PhoneRounded';
import { KIFFGO } from '../../../library/kiffgo';
import { Link } from 'gatsby';
import YourQuoteIsValidFor from '../YourQuoteIsValidFor/YourQuoteIsValidFor';

const BookingHeader = (props) => (
  <>
    {!props.hideQuoteTimeout && <YourQuoteIsValidFor />}
    <div className={css(styles.root)}>
      <div className={css(styles.content)}>
        <div className={css(styles.stepsHeader)}>
          <Link className={css(styles.arrowBack)} to={props.backUrl || '/'}>
            <ArrowBack className={css(styles.arrowBackIcon)} />
          </Link>

          <div className={css(styles.logoContainer)}>
            <img alt={'JJD - Man and van'} className={css(styles.logo)} src={require('../../../assets/images/jjd-logo.png')} />
          </div>

          {/* <a className={css(styles.phoneNumber)} href="javascript:void(Chatra('openChat', true))">Message Us</a>
          <a className={css(styles.phoneNumberIcon)} href="javascript:void(Chatra('openChat', true))">
            <PhoneIcon className={css(styles.phoneIcon)} />
          </a> */}
        </div>
      </div>
    </div>
  </>
);

export default BookingHeader;
