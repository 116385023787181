import _ from "lodash";
import React, { Component } from "react";
import connect from "react-redux/es/connect/connect";
import MapView from "./MapView";

export const CENTRAL_LONDON = { lat: 51.509099, lng: -0.126541 };

const getFinalData = (locations) => {
  const finalData = [];

  locations.forEach((element) => {
    if (element.latitude && element.longitude) {
      //
      finalData.push({
        lat: element.latitude,
        lng: element.longitude,
      });
    }
  });

  return finalData;
};

export default class MapViewContainer extends Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      locations: getFinalData(nextProps.locations),
    };
  }

  constructor(props) {
    super(props);
    this.mapReference = React.createRef();
    this.initalAutoFit = false;
    this.state = {
      center: CENTRAL_LONDON,
      locations: getFinalData(props.locations),
    };
  }

  componentDidMount() {
    /* if (this.state.locations.length > 0) {
      setTimeout(() => {
        this.autoFitMapMarkers(this.state.locations);
      }, 1000);
    } */
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevState.locations, this.state.locations)) {
      this.autoFitMapMarkers(this.state.locations);
    }
  }

  autoFitMapMarkers(currentCoords) {
    if (currentCoords.length) {
      if (currentCoords.length === 1) {
        // set center instead
        this.setState({ center: currentCoords[0] });
      } else {
        const bounds = new window.google.maps.LatLngBounds();
        for (var i = 0; i < currentCoords.length; i++) {
          bounds.extend(currentCoords[i]);
        }

        this.mapReference.fitBounds(bounds);
      }
    }
  }

  onTilesLoaded = () => {
    if (!this.initalAutoFit && this.state.locations.length > 0) {
      this.autoFitMapMarkers(this.state.locations);
      this.initalAutoFit = true;
    }
  };

  render() {
    const { center, locations } = this.state;
    return (
      <MapView
        {...this.props}
        data={locations}
        center={center}
        mapRef={(node) => (this.mapReference = node)}
        onTilesLoaded={this.onTilesLoaded}
      />
    );
  }
}
