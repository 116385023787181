import { StyleSheet } from 'aphrodite';
import COLORS from '../../../styles/colors';
import QUERIES from '../../../styles/queries';

const styles = StyleSheet.create({
  root: {
    // minHeight: '100vh'
    background: COLORS.white,
    // borderBottom: '1px solid whitesmoke',
    height: 72,
    position: 'relative'
  },
  content: {
    // maxWidth: 1200,
    margin: '0 auto',
    width: '100%'
  },
  arrowBack: {
    height: 72,
    padding: 24,
    position: 'absolute',
    left: 0,
    top: 0
  },
  arrowBackIcon: {
    color: COLORS.offBlack
  },
  stepsHeader: {
    display: 'flex',
    flexDirection: 'row'
  },
  title: {
    margin: 0,
    fontSize: 22,
    fontWeight: 'bold',
    lineHeight: '72px',
    textAlign: 'center',
    flex: 1
  },
  logoContainer: {
    margin: 0,
    height: 72,
    textAlign: 'center',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  logo: {
    height: "100%",
    width: 100,
  },
  nextButton: {
    textTransform: 'none',
    fontWeight: 'bold',
    boxShadow: 'none'
  },
  phoneNumber: {
    padding: 24,
    fontWeight: 'bold',
    textDecoration: 'none',
    color: COLORS.offBlack,
    position: 'absolute',
    right: 0,
    top: 0,
    [QUERIES.mobile]: {
      display: 'none'
    }
  },
  phoneIcon: {
    height: 72,
    // paddingRight: 24,
    position: 'absolute',
    right: 24,
    top: 0,
    color: '#a7a7a7',
    [QUERIES.desktop]: {
      display: 'none'
    }
  }
});

export default styles;
