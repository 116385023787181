import { StyleSheet } from 'aphrodite';
import COLORS from '../../../styles/colors';
import QUERIES from '../../../styles/queries';

const styles = StyleSheet.create({
  root: {
    padding: 16,
    background: 'white',
    boxShadow: '0 8px 40px rgba(0,0,0,0.08)',
    borderTopLeftRadius: 22,
    borderTopRightRadius: 22,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%'
  },
  reviewStar: {
    color: COLORS.starYellow,
  },
  excellence: {
    fontSize: 11,
    fontWeight: 'bold',
    color: COLORS.lightBlack,
    opacity: 0.35,
    paddingTop: 6
  },
  barkLogo: {
    width: 80,
    padding: 8
  },
  trustPilotLogo: {
    height: 28
  },
  noOfBookings: {
    fontSize: 11,
    paddingTop: 6,
    paddingLeft: 4,
    color: COLORS.lightBlack,
    fontWeight: 'bold'
  },
  teamImage: {
    height: 84 - 32,
    marginRight: 16,
    [QUERIES.mobile]: {
      display: 'none'
    }
  },
  trustPilotStar: {
    height: 24,
    background: '#00b67a',
    marginRight: 4,
    marginBottom: 4
  },
  trustPilotNoStar: {
    background: '#dcdce5'
  }
});

export default styles;
