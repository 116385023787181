import { StyleSheet } from 'aphrodite';
import COLORS from '../../../styles/colors';

const styles = StyleSheet.create({
  root: {
    width: '100%',
    height: 72,
    background: '#f5f5f5',
    textAlign: 'center',
    fontSize: 14,
    color: COLORS.hotPink,
    fontWeight: 'bold',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
});

export default styles;
