import React, { Component } from "react";
import BookingSteppers from "../../../components/booking/BookingHeader/BookingHeader";
import connect from "react-redux/es/connect/connect";
import {
  selectAddress,
  fetchSuggestions,
  editAddress,
  editAdditionalStopAddress,
} from "./RoutePlannerActions";
import BookingAddresses from "./RoutePlanner";
import ReactGA from "react-ga";
import ReactPixel from "react-facebook-pixel";
import { navigate } from "gatsby";
import { window } from "browser-monads";
import Utils from "../../../library/utils";
import { clearAddress } from "../../../actions/AddressActions";
import { calculateCustomPrices } from "../../../actions/PriceActions";
import {
  addAdditionalStop,
  removeAdditionalStop,
  selectAdditionalStopAddress,
} from "../../../actions/AdditionalStopsActions";
import { resetYourQuoteIsValidFor } from "../../../components/booking/YourQuoteIsValidFor/YourQuoteIsValidForActions";

class RoutePlannerContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pickup: "",
      dropoff: "",

      // Additional Stops:
      additionalStop0: "",
      additionalStop1: "",
      additionalStop2: "",
      additionalStop3: "",

      // Errors:
      pickupMenuError: null,
      dropoffMenuError: null,

      // Show suggestions?
      showPickupSuggestions: false,
      showDropoffSuggestions: false,
      showAdditionalStop0Suggestions: false,
      showAdditionalStop1Suggestions: false,
      showAdditionalStop2Suggestions: false,
      showAdditionalStop3Suggestions: false,
      loading: false,
    };

    this.activeTimeout = null;
  }

  componentDidMount() {
    // ADDITIONAL STOPS:
    for (let i = 0; i <= 4; i++) {
      if (
        this.props.bookingAdditionalStops.stops[i] &&
        this.props.bookingAdditionalStops.stops[i].fullAddress
      ) {
        this.setState({
          [`additionalStop${i}`]: this.props.bookingAdditionalStops.stops[i]
            .fullAddress,
        });
      }
    }

    // PICKUP:
    if (this.props.bookingAddresses.pickup.fullAddress) {
      this.setState({
        pickup: this.props.bookingAddresses.pickup.fullAddress,
      });
    } else if (this.props.getAQuote.pickupPostcode) {
      this.setState(
        {
          pickup: this.props.getAQuote.pickupPostcode,
          showPickupSuggestions: true,
        },
        () => {
          this.props.fetchSuggestions("pickup", this.state.pickup);
        }
      );
    } else if (!Utils.isBlank(this.props.unfinishedAddressQueries.pickup)) {
      this.setState(
        {
          pickup: this.props.unfinishedAddressQueries.pickup,
          showPickupSuggestions: true,
        },
        () => {
          this.props.fetchSuggestions("pickup", this.state.pickup);
        }
      );
    }

    // DROPOFF:
    if (this.props.bookingAddresses.dropoff.fullAddress) {
      this.setState({
        dropoff: this.props.bookingAddresses.dropoff.fullAddress,
      });
    } else if (this.props.getAQuote.dropoffPostcode) {
      this.setState(
        {
          dropoff: this.props.getAQuote.dropoffPostcode,
          showDropoffSuggestions: true,
        },
        () => {
          this.props.fetchSuggestions("dropoff", this.state.dropoff);
        }
      );
    } else if (!Utils.isBlank(this.props.unfinishedAddressQueries.dropoff)) {
      this.setState(
        {
          dropoff: this.props.unfinishedAddressQueries.dropoff,
          showDropoffSuggestions: true,
        },
        () => {
          this.props.fetchSuggestions("dropoff", this.state.dropoff);
        }
      );
    }

    window.scrollTo(0, 0);
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.unfinishedAddressQueries.pickup !==
      this.props.unfinishedAddressQueries.pickup
    ) {
      this.setState(
        {
          pickup: nextProps.unfinishedAddressQueries.pickup,
          showPickupSuggestions: true,
        },
        () => {
          this.props.fetchSuggestions("pickup", this.state.pickup);
        }
      );
    }
    if (
      nextProps.unfinishedAddressQueries.dropoff !==
      this.props.unfinishedAddressQueries.dropoff
    ) {
      this.setState(
        {
          dropoff: nextProps.unfinishedAddressQueries.dropoff,
          showDropoffSuggestions: true,
        },
        () => {
          this.props.fetchSuggestions("dropoff", this.state.dropoff);
        }
      );
    }

    for (let i = 0; i <= 4; i++) {
      if (
        nextProps.bookingAdditionalStops.stops[i] &&
        this.props.bookingAdditionalStops.stops[i]
      ) {
        if (
          nextProps.bookingAdditionalStops.stops[i].fullAddress !==
          this.props.bookingAdditionalStops.stops[i].fullAddress
        ) {
          this.setState(
            {
              [`additionalStop${i}`]: nextProps.bookingAdditionalStops.stops[i]
                .fullAddress,
              [`showAdditionalStop${i}Suggestions`]: false,
            },
            () => console.log(this.state)
          );
        }
      }
    }

    if (
      nextProps.bookingAddresses.pickup.fullAddress !==
      this.props.bookingAddresses.pickup.fullAddress
    ) {
      this.setState({
        pickup: nextProps.bookingAddresses.pickup.fullAddress,
        showPickupSuggestions: false,
      });
    }

    if (
      nextProps.bookingAddresses.dropoff.fullAddress !==
      this.props.bookingAddresses.dropoff.fullAddress
    ) {
      this.setState({
        dropoff: nextProps.bookingAddresses.dropoff.fullAddress,
        showDropoffSuggestions: false,
      });
    }

    if (
      !!nextProps.bookingAddresses.pickup.postcodeError &&
      nextProps.bookingAddresses.pickup.postcodeError !==
        this.props.bookingAddresses.pickup.postcodeError
    ) {
      this.props.editAddress("pickup");
    }

    if (
      !!nextProps.bookingAddresses.dropoff.postcodeError &&
      nextProps.bookingAddresses.dropoff.postcodeError !==
        this.props.bookingAddresses.dropoff.postcodeError
    ) {
      this.props.editAddress("dropoff");
    }
  }

  editAdditionalStopAddress = () => {};

  goNext = () => {
    ReactGA.event({
      category: "Web booking",
      action: "Has selected locations",
      label: "RoutePlanner",
    });
    ReactPixel.track("Lead");
    this.props.calculateCustomPrices();
    this.props.resetYourQuoteIsValidFor();
    this.setState(
      {
        loading: true,
      },
      () => {
        setTimeout(() => {
          navigate("/choose-van-size");
        }, 1600);
      }
    );
  };

  handlePickupChange = (e) => {
    this.props.clearAddress("pickup");
    this.activeTimeout && clearTimeout(this.activeTimeout);
    this.setState(
      {
        pickup: e.target.value,
      },
      () => {
        this.activeTimeout = setTimeout(() => {
          if (this.state.pickup.length >= 3) {
            this.props.fetchSuggestions("pickup", this.state.pickup);
            this.setState({
              showPickupSuggestions: true,
            });
          } else if (this.state.pickup.length === 0) {
            this.setState({
              showPickupSuggestions: false,
            });
          }
        }, 500);
      }
    );
  };

  handleDropoffChange = (e) => {
    this.props.clearAddress("dropoff");
    this.activeTimeout && clearTimeout(this.activeTimeout);
    this.setState(
      {
        dropoff: e.target.value,
      },
      () => {
        this.activeTimeout = setTimeout(() => {
          if (this.state.dropoff.length >= 3) {
            this.props.fetchSuggestions("dropoff", this.state.dropoff);
            this.setState({
              showDropoffSuggestions: true,
            });
          } else if (this.state.pickup.length === 0) {
            this.setState({
              showDropoffSuggestions: false,
            });
          }
        }, 500);
      }
    );
  };

  handleAdditionalStopChange = (e, index) => {
    const stop = `additionalStop${index}`;
    const suggestions = `showAdditionalStop${index}Suggestions`;
    this.activeTimeout && clearTimeout(this.activeTimeout);

    this.setState(
      {
        [stop]: e.target.value,
      },
      () => {
        this.activeTimeout = setTimeout(() => {
          if (this.state[stop].length >= 3) {
            this.props.fetchSuggestions(stop, this.state[stop]);
            this.setState({
              [suggestions]: true,
            });
          } else if (this.state[stop].length === 0) {
            this.setState({
              [suggestions]: false,
            });
          }
        });
      }
    );
  };

  selectAdditionalStopAddress = (hit, index) => {
    const stop = `additionalStop${index}`;
    this.setState({
      [stop]: hit.suggestion,
    });

    this.props.selectAdditionalStopAddress(stop, hit, index);
  };

  selectPickupAddress = (hit) => {
    this.setState({
      pickup: hit.suggestion,
    });

    this.props.selectAddress("pickup", hit);
  };

  selectDropoffAddress = (hit) => {
    this.setState({
      dropoff: hit.suggestion,
    });

    this.props.selectAddress("dropoff", hit);
  };

  pickupValid = () => {
    let valid = true;
    const missingPostcode = !this.props.bookingAddresses.pickup.postcode;
    const missingAddressLine = !this.props.bookingAddresses.pickup.line_1;
    const postcodeHasError = !!this.props.bookingAddresses.pickup.postcodeError;
    if (missingPostcode || missingAddressLine || postcodeHasError) {
      valid = false;
    }

    return valid;
  };

  allAddressesValid = () => {
    let valid = true;
    const missingPostcode =
      !this.props.bookingAddresses.pickup.postcode ||
      !this.props.bookingAddresses.dropoff.postcode;
    const missingAddressLine =
      !this.props.bookingAddresses.pickup.line_1 ||
      !this.props.bookingAddresses.dropoff.line_1;
    const postcodeHasError =
      !!this.props.bookingAddresses.pickup.postcodeError ||
      !!this.props.bookingAddresses.dropoff.postcodeError;
    if (missingPostcode || missingAddressLine || postcodeHasError) {
      valid = false;
    }

    for (const additionalStop of this.props.bookingAdditionalStops.stops) {
      if (
        additionalStop.postcodeValid === false &&
        additionalStop.selectedFromSuggestion === false
      ) {
        valid = false;
      }
    }

    return valid;
  };

  everythingValid = () => {
    let valid = this.allAddressesValid();
    // if (this.state.dropoffStairsIndex === null || this.state.pickupStairsIndex === null) {
    //   valid = false
    // }
    return valid;
  };

  handleFocus = (event) => event.target.select(); // Focus on whole input

  removeStop = (index) => {
    this.setState(
      {
        [`additionalStop${index}`]: "",
      },
      () => this.props.removeAdditionalStop(index)
    );
  };

  render() {
    return (
      <div style={{ height: "100vh" }}>
        <BookingSteppers
          hideQuoteTimeout={true}
          title={"Confirm Address"}
          number={2}
          goNext={this.goNext}
          disabled={!this.everythingValid()}
        />
        <BookingAddresses
          // Props:
          {...this.props}
          loading={this.state.loading}
          goNext={this.goNext}
          disabled={!this.everythingValid()}
          // Change:
          handlePickupChange={this.handlePickupChange}
          handleDropoffChange={this.handleDropoffChange}
          handleAdditionalStopChange={this.handleAdditionalStopChange}
          // Select:
          selectPickupAddress={this.selectPickupAddress}
          selectDropoffAddress={this.selectDropoffAddress}
          selectAdditionalStopAddress={this.selectAdditionalStopAddress}
          // Focus:
          handleFocus={this.handleFocus}
          // State:
          pickup={this.state.pickup}
          dropoff={this.state.dropoff}
          additionalStop0={this.state.additionalStop0}
          additionalStop1={this.state.additionalStop1}
          additionalStop2={this.state.additionalStop2}
          additionalStop3={this.state.additionalStop3}
          showPickupSuggestions={this.state.showPickupSuggestions}
          showDropoffSuggestions={this.state.showDropoffSuggestions}
          showAdditionalStop0Suggestions={
            this.state.showAdditionalStop0Suggestions
          }
          showAdditionalStop1Suggestions={
            this.state.showAdditionalStop1Suggestions
          }
          showAdditionalStop2Suggestions={
            this.state.showAdditionalStop2Suggestions
          }
          showAdditionalStop3Suggestions={
            this.state.showAdditionalStop3Suggestions
          }
          removeStop={this.removeStop}
          pickupValid={this.pickupValid}
          allAddressesValid={this.allAddressesValid}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    bookingAddresses: state.bookingAddresses,
    getAQuote: state.getAQuote,
    unfinishedAddressQueries: state.unfinishedAddressQueries,
    bookingAdditionalStops: state.bookingAdditionalStops,
    bookingDistance: state.bookingDistance,
  };
}

const mapDispatchToProps = {
  editAddress: editAddress,
  selectAddress: selectAddress,
  fetchSuggestions: fetchSuggestions,
  clearAddress: clearAddress,
  calculateCustomPrices: calculateCustomPrices,
  addStop: addAdditionalStop,
  removeAdditionalStop: removeAdditionalStop,
  selectAdditionalStopAddress: selectAdditionalStopAddress,
  editAdditionalStopAddress: editAdditionalStopAddress,
  resetYourQuoteIsValidFor: resetYourQuoteIsValidFor,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RoutePlannerContainer);
