import { StyleSheet } from "aphrodite";
import QUERIES from "../../../styles/queries";
import COLORS from "../../../styles/colors";
import { FONTS } from "../../../styles/fonts";

const styles = StyleSheet.create({
  root: {},
  fromToLabel: {
    fontSize: 14,
    padding: 16,
    color: "#aeaeae",
  },
  bgImage: {
    position: "fixed",
    zIndex: -1,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    maxWidth: "100%",
    maxHeight: "100%",
    margin: "auto",
    overflow: "auto",
    opacity: 0.12,
  },
  content: {
    margin: "0 auto",
    maxWidth: 1200,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    flexWrap: "wrap",
    [QUERIES.desktop]: {
      padding: 16,
      alignItems: "flex-start",
    },
  },
  titleArea: {
    textAlign: "center",
    height: 32,
  },
  innerContent: {
    flexWrap: "wrap",
    display: "flex",
    marginTop: 24,
    boxShadow: "none",
    flexDirection: "row",
  },
  customInput: {
    width: "100%",
    height: 70,
    margin: 0,
    padding: 32,
    fontSize: 18,
    fontWeight: "bold",
    background: COLORS.white,
    color: COLORS.jjdGreyish,
    boxShadow: "0 6px 14px 0 rgba(0, 0, 0, 0.1)",
    border: "1px solid transparent",
    borderRadius: 48,
    paddingRight: 55,
  },
  pickupInput: {
    [QUERIES.desktop]: {},
  },
  dropoffInput: {
    border: "none",
    //borderTop: '1px solid whitesmoke',
    [QUERIES.desktop]: {
      //border: '1px solid whitesmoke',
      borderLeft: "none",
    },
  },
  additionalInputContainer: {
    [QUERIES.desktop]: {
      ":before": {
        content: "''",
        position: "absolute",
        left: 30,
        zIndex: -999,
        top: -25,
        height: "100%",
        minHeight: 120,
        width: "100%",
        backgroundImage:
          "linear-gradient(rgba(5, 5, 5, 1) 60%, rgba(255,255,255,0) 70%)",
        backgroundPosition: "left",
        backgroundSize: "2px 21px",
        backgroundRepeat: "repeat-y",
      },
      ":after": {
        content: "''",
        display: "block",
        width: 12,
        height: 12,
        backgroundColor: "white",
        position: "absolute",
        left: 24,
        top: 24,
        borderRadius: "50%",
        border: "2px solid black",
      },
    },
  },
  additionalInput: {
    fontWeight: "normal",
    paddingRight: 110,
    [QUERIES.desktop]: {
      marginLeft: 60,
      ":before": {
        content: "''",
        display: "block",
        width: 20,
        height: 20,
        backgroundColor: COLORS.black,
        position: "absolute",
        left: 0,
        color: COLORS.black,
      },
    },
  },
  inputContainer: {
    position: "relative",
    display: "flex",
    flex: "0 0 100%",
    [QUERIES.mobile]: {},
  },
  menu: {
    top: 0,
  },
  suggestionsContainer: {
    boxShadow: "0 16px 40px rgba(0,0,0,0.12)",
    maxHeight: "60vh",
    overflowY: "scroll",
    position: "absolute",
    top: 78,
    left: 0,
    right: 0,
    zIndex: 11,
    background: COLORS.white,
  },
  editButton: {
    border: "none",
    background: "transparent",
    color: "#aeaeae",
    fontSize: 10,
    fontWeight: "bold",
    borderRadius: 4,
    width: 64,
    height: "100%",
    padding: 24,
    position: "absolute",
    right: 0,
    minWidth: 70,
    ":hover": {
      color: "black",
      cursor: "pointer",
    },
  },
  removeBtn: {
    border: "none",
    background: "transparent",
    color: "#aeaeae",
    fontSize: 10,
    fontWeight: "bold",
    borderRadius: 4,
    width: 64,
    height: "100%",
    padding: 24,
    position: "absolute",
    right: 50,
    minWidth: 70,
    ":hover": {
      color: "black",
      cursor: "pointer",
    },
  },
  nextButtonContainer: {
    width: "100%",
    margin: "0 auto",
    marginTop: 64,
    [QUERIES.mobile]: {
      width: "calc(100% - 32px)",
      padding: 16,
      marginTop: 0,
    },
    //maxWidth: 800
  },

  mapImage: {
    width: "100%",
    maxWidth: 399,
    height: 569,
    borderRadius: 12,
  },
  inputBoxes: {
    flex: 2,
    [QUERIES.lessThan800]: {
      margin: "0 auto",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  additionalBtn: {
    borderRadius: 48,
    color: COLORS.jjdGreyish,
    background: COLORS.white,
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 6px 14px 0px",
    textTransform: "capitalize",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "0 auto",
    width: 270,
    height: 70,
    fontSize: 18,
    fontFamily: "Nunito",
  },
  getQuoteBtn: {
    borderRadius: 48,
    textTransform: "capitalize",
    margin: "30px auto 130px auto",
    width: 382,
    height: 65,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 20,
    fontFamily: "Nunito",
    [QUERIES.lessThan600]: {
      width: "100%",
      padding: "0 120px",
    },
  },
  mapImageContainer: {
    flex: 2,
    height: "490px",
    marginLeft: "50px",
    borderRadius: "10px",
    overflow: "hidden",
    [QUERIES.lessThan600]: {
      display: "none",
    },
  },

  sellingPointsList: {
    fontSize: 14,
    margin: 0,
    paddingBottom: 16,
    marginTop: "30px",
    paddingLeft: 0,
  },
  sellingPointItem: {
    fontSize: 14,
    paddingBottom: 4,
    fontWeight: "normal",
  },
  sellingPointCheck: {
    width: 14,
    height: 18,
    marginRight: 6,
    paddingTop: 6,
    // color: '#d6d6d6'
  },
});

export default styles;
