// @flow
import React from "react";
import PropTypes from "prop-types";

import TrustPilotSmallView from "./TrustPilotSmallView";

export default class TrustPilotSmallController extends React.Component {
  static propTypes = {};

  static defaultProps = {};

  render() {
    return <TrustPilotSmallView {...this.props} />;
  }
}
